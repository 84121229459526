import React from "react"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/CountryReportsPage/HeroSection/HeroSection"
import InternationalCoverage from "../components/CountryReportsPage/InternationalSection/InternationalCoverage"
import TrulyGlobal from "../components/CountryReportsPage/TrulyGlobal/TrulyGlobal"
import Flags from "../components/CountryReportsPage/Flags/Flags"
import SEO from "../components/Global/SEO/seo"

const CountryReports = () => {
  return (
    <Layout
      headerAbsolute
      headerTransparent
      textlight
      logoDark={false}
      whiteToggler
    >
      <SEO page="country-reports" />
      <HeroSection />
      <InternationalCoverage />
      <TrulyGlobal />
      <Flags />
    </Layout>
  )
}

export default CountryReports
